import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			// deviceType: 'pc',
			deviceType: 'mobile',
			adsensConfig: {
				scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6027765511032066',
				ads: 'google.com, pub-6027765511032066, DIRECT, f08c47fec0942fa0',
				home_1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6027765511032066', 'data-ad-slot': '9894', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				home_2: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6027765511032066', 'data-ad-slot': '5264', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				home_3: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6027765511032066', 'data-ad-slot': '3348', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				home_4: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6027765511032066', 'data-ad-slot': '3328', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				answer_1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6027765511032066', 'data-ad-slot': '8389', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				answer_2: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6027765511032066', 'data-ad-slot': '7096', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				blog_1: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6027765511032066', 'data-ad-slot': '5783', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
				blog_2: { class: 'adsbygoogle', style: 'display:block', 'data-ad-client': 'ca-pub-6027765511032066', 'data-ad-slot': '8848', 'data-ad-format': 'auto', 'data-full-width-responsive': 'true' },
			},
			showDebug: false,
			firebaseConfig: {
				apiKey: 'AIzaSyB4UwgXrurDMUbwGZuQTIgglU4pejcOoNk',
				authDomain: 'thevergeghi-shop01.firebaseapp.com',
				projectId: 'thevergeghi-shop01',
				storageBucket: 'thevergeghi-shop01.appspot.com',
				messagingSenderId: '7686472466',
				appId: '1:7686472466:web:bc294ec76e2fa6e9e529cb',
				measurementId: 'G-CRPMG7252K',
			},
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsensConfig: (state) => state.adsensConfig,
		},
	})
}
